<!-- <div class="main-content">
    <div class="row">
        <div class="col-2"><img src="../../assets/images/solar_logo3_24bit.png"> </div>
        <div class="col-2">
            <div *ngIf="!currentUser"> <i class="pi pi-lock"></i> <span (click)="signInClick()">Sign In</span></div>
            <div *ngIf="currentUser"> <i class="pi pi-unlock"></i> <span (click)="signOutClick()">Sign Out</span></div>

            <p>{{ CurrentDate | date:'dd-MM-yyyy'}} <span>{{ now | date:'HH:mm:ss'}}</span> </p>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-6">
                    <p-dropdown [options]="publicSites" [(ngModel)]="selectedPublicSite" placeholder="PublicSites"
                        optionLabel="name" [showClear]="true" appendTo="body" [style]="{'height':'25px'}"></p-dropdown>
                </div>
                <div class="col-6">
                    <p-dropdown *ngIf="currentUser" [options]="appSites" [(ngModel)]="selectedAppSite" placeholder="My Applications"
                        appendTo="body" [style]="{'height':'25px'}" (onChange)="onAppChange($event)"></p-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p-tabView *ngIf="currentUser" (onClose)="handleClose($event)">
                        <p-tabPanel [header]="item.header" *ngFor="let item of items; let i = index" [closable]="true">

                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>

        </div> 
        <div class="col-2" *ngIf="currentUser">Welcome <label>{{currentUser.username}}</label></div>
    </div>
</div>

<p-dialog header="Please Sign in" [(visible)]="display" [style]="{width: '30vw'}" [baseZIndex]="10000">
    <app-login (loginCompleted)="loginCompleted($event)"></app-login>
</p-dialog>-->
<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>
<div class="navbar-custom">
    <div class="row">
        <div class="col-2"><img src="../../assets/images/Logo.png" /></div>
        <div class="col-10">
            <div class="app-search" *ngIf="authService.userLoggedin">
                <div style="width: 210px;position: relative;float: right;">
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px"
                        height="24px">
                        <path
                            d="M 13.261719 14.867188 L 15.742188 17.347656 C 15.363281 18.070313 15.324219 18.789063 15.722656 19.1875 L 20.25 23.714844 C 20.820313 24.285156 22.0625 23.972656 23.015625 23.015625 C 23.972656 22.058594 24.285156 20.820313 23.714844 20.25 L 19.191406 15.722656 C 18.789063 15.324219 18.070313 15.363281 17.347656 15.738281 L 14.867188 13.261719 Z M 8.5 0 C 3.804688 0 0 3.804688 0 8.5 C 0 13.195313 3.804688 17 8.5 17 C 13.195313 17 17 13.195313 17 8.5 C 17 3.804688 13.195313 0 8.5 0 Z M 8.5 15 C 4.910156 15 2 12.089844 2 8.5 C 2 4.910156 4.910156 2 8.5 2 C 12.089844 2 15 4.910156 15 8.5 C 15 12.089844 12.089844 15 8.5 15 Z" />
                    </svg> <input class="form-control toupper" [(ngModel)]="searchText" placeholder="Search"
                        (keyup.enter)="search($event.target.value)">
                </div>
            </div>
            <div class="logged-user-menu" (click)="op.toggle($event)" *ngIf="authService.userLoggedin">
                <div class="round-shape"><img /></div>
                <div class="logged-user large-scr">Welcome <br>{{currentUser.username}}</div>
                <div class="logged-user small-scr">{{currentUser.username.charAt(0)}}</div>
                <div class="icon-angle"><i class="pi pi-chevron-circle-down"
                        style="padding:20px 5px; color:#bcc1cc"></i></div>
            </div>

        </div>
        <!-- <div class="col-2">
            <div class="logged-user-menu" (click)="op.toggle($event)" *ngIf="authService.userLoggedin">
                <div class="round-shape"><img /></div>
                <div class="logged-user">Welcome <br>{{currentUser.username}}</div>
                <div class="icon-angle"><i class="pi pi-chevron-circle-down" style="padding:20px 5px; color:#bcc1cc"></i></div>
            </div>
        </div> -->
    </div>
</div>
<p-overlayPanel #op [style]="{'min-width':'200px', 'min-height':'50px'}">
    <ng-template pTemplate>
        <a (click)="signOutClick()"> <i class="pi pi-lock-open"></i> <span>Sign Out</span></a>
    </ng-template>
</p-overlayPanel>
<p-dialog header="" [(visible)]="display" [style]="{width: '30vw'}">

    <div>
        Attempt to access undefined subsystem.
    </div>

    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="display=false;" label="Ok"></button>
    </p-footer>
</p-dialog>