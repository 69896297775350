import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { tokenName } from '@angular/compiler';
const store = require('store');
const STORE_KEY = 'lastAction';
export const TOKEN_NAME = 'jwt_token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  logoutTime = 10;
  interval: any;
  private tokenURL;
  tokenInterval: any;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.tokenURL = 'http://keycloak.bcx-solar.co.za/auth/realms/solar-dev-realm/protocol/openid-connect/token';
  }
  get lastAction() {
    return store.get(STORE_KEY) as number;
  }
  set lastAction(value) {
    store.set(STORE_KEY, value);
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(uname, pwd) {
    const user = {
      client_id: 'solar-dev-web',
      username: uname,
      password: pwd,
      grant_type: 'password'
    };
    // const body = new HttpParams();
    // body.set('client_id', 'solar-dev-web');
    // body.set('username', uname);
    // body.set('password', pwd);
    // body.set('grant_type', 'password');
    // return await this.http.post(this.tokenURL, user, this.httpOptions)
    //   .subscribe((data: any) => {
    //     this.setToken(data.access_token);
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //     return true;
    //   }, error => {
    //     return false;
    //   });
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.check();
    this.initInterval();
    // this.getTokenDetails();
    this.initTokenInterval();
    this.currentUserSubject.next(user);
    return user;

  }
  public get userLoggedin(): boolean {
    const userdetails = localStorage.getItem('currentUser');
    if (userdetails === undefined || userdetails === null) {
      return false;
    }
    return true;
  }
  logout() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.tokenInterval) {
      clearInterval(this.tokenInterval);
    }
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem(TOKEN_NAME);
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);

  }
  ngOnDestroy() {
    this.currentUserSubject.unsubscribe();
  }

  updateUser(user) {
    this.currentUserSubject.next(user);
  }
  public reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + this.logoutTime * 60 * 1000;
    const diff: any = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout && this.userLoggedin) {
      this.logout();
    }
  }
  private initInterval() {
    this.interval = setInterval(() => {
      this.check();
    }, 1000);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }
  getTokenDetails() {
    this.http.post(this.tokenURL, '', this.httpOptions)
      .subscribe((data: any) => {
        this.setToken(data.access_token);
      });
  }
  private initTokenInterval() {
    // this.tokenInterval = setInterval(() => {
    //   this.getTokenDetails();
    // }, 3540 * 1000);
  }

  getTokenServiceTokenValue() {
    return 'Basic a0VEeVhrWG4xZG5NYnRGbUk2VDkyVFc3RGMwYTpHNmRkM1p2bER5R1Q3RUhHemFQelRleGl2YzBh';
  }
}
