// import { Observable } from 'rxjs/Observable';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/observable/fromPromise';
import { Observable } from 'rxjs/internal/Observable';
import { from as fromPromise } from 'rxjs';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return fromPromise(this.handleAccess(request, next));
    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
        Promise<HttpEvent<any>> {
        const token = await this.authService.getToken();
        let changedRequest = request;
        // HttpHeader object immutable - copy values
        const headerSettings: { [name: string]: string | string[]; } = {};

        for (const key of request.headers.keys()) {
            headerSettings[key] = request.headers.getAll(key);
        }
        if (request.url.indexOf('token?grant_type=client_credentials') > -1) {
            headerSettings.Authorization = 'Basic a0VEeVhrWG4xZG5NYnRGbUk2VDkyVFc3RGMwYTpHNmRkM1p2bER5R1Q3RUhHemFQelRleGl2YzBh';
        } else if (token) {
            headerSettings.Authorization = 'Bearer ' + token;
        }
        // headerSettings['Content-Type'] = 'application/x-www-form-urlencoded';
        const newHeader = new HttpHeaders(headerSettings);

        changedRequest = request.clone({
            headers: newHeader
        });
        return next.handle(changedRequest).toPromise();
    }

}
