<div class="container">
    <div class="row">

        <div class="div-center col-md-12 offset-md-6">
            <div class="content">
            <div class="header">
                <img src='../../assets/images/Logo.png'>
            </div>
            <div *ngIf="invalidCred" class="invalid-cred">
                Invalid Credentials.
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="login" >

                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="custom-submit text-white">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>
                    &nbsp;
                    <button type="button" class="warn" (click)="onClear()">
                        Clear
                    </button>
                </div>
            </form>
        </div>
        </div>
    </div>
</div>