<!-- <div class="home-content" *ngIf="isUserLoggedIn && currentUser.selectedApp">
    <div class="row">
        <div class="col-3">
            <p-panelMenu [model]="items" [style]="{'width':'300px'}"></p-panelMenu>
        </div>
        <div class="col-9"> -->
<img class="solar_main_image" *ngIf="isHomeLoaded" src="../../assets/images/bcx_solar.png" />
<!--  <router-outlet >
                
            </router-outlet> -->
<!-- <div *ngIf="!isHomeLoaded"> -->
<!-- <router-outlet (activate)='onActivate($event)' (deactivate)='onDeactivate($event)'></router-outlet> -->
<!-- </div> -->
<!--  </div>
    </div>
</div> -->