import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './helpers/auth.guard';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard], component: HomeComponent,
    // loadChildren: () => import('./meter-readings/meter-readings.module').then(m => m.MeterReadingsModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'meters',
    canActivate: [AuthGuard],
    loadChildren: () => import('./meter-readings/meter-readings.module').then(m => m.MeterReadingsModule)
  },
  {
    path: 'meters',
    canActivate: [AuthGuard],
    loadChildren: () => import('./meter-reading-history/meter-reading-history.module').then(m => m.MeterReadingHistoryModule)
  },
  {
    path: 'debtor',
    canActivate: [AuthGuard],
    loadChildren: () => import('./debtor-accounts/debtor-accounts.module').then(m => m.DebtorAccountsModule)
  },
  {
    path: 'stands',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stands/stands.module').then(m => m.StandsModule)
  },
  {
    path: 'journals',
    canActivate: [AuthGuard],
    loadChildren: () => import('./journals/journals.module').then(m => m.JournalsModule)
  },
  {
    path: 'stands',
    canActivate: [AuthGuard],
    loadChildren: () => import('./bf300-stands/bf300-stands.module').then(m => m.Bf300StandsModule)
  },
  {
    path: 'bh-services',
    canActivate: [AuthGuard],
    loadChildren: () => import('./bh-services/basic-services/basic-services.module').then(m => m.BasicServicesModule)
  },
  {
    path: 'general-ledger',
    canActivate: [AuthGuard],
    loadChildren: () => import('./general-ledger/installation-codes/installation-codes.module').then(m => m.InstallationCodesModule)
  },
  {
    path: 'general-ledger',
    canActivate: [AuthGuard],
    loadChildren: () => import('./general-ledger/functional-codes/functional-codes.module').then(m => m.FunctionalCodesModule)
  },
  {
    path: 'general-ledger',
    canActivate: [AuthGuard],
    loadChildren: () => import('./general-ledger/chart-accounts-entity/chart-accounts-entity.module').then(m => m.ChartAccountsEntityModule)
  },
  {
    path: 'general-ledger',
    canActivate: [AuthGuard],
    loadChildren: () => import('./general-ledger/budgeting/budgeting.module').then(m => m.BudgetingModule)
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
