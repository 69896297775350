import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  CurrentDate = new Date();
  currentUser: any = {};
  currentUserSubs: any;
  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUserSubs = this.authService.currentUser.subscribe(item => {
      // console.log(item);
      this.currentUser = item;
    });
    setInterval(() => {
      this.CurrentDate = new Date();
    }, 1);
  }

}
