import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isUserLoggedIn = false;
  currentUserSubs: any;
  items: any[];
  isHomeLoaded = true;
  currentUser: any;

  constructor(private authService: AuthenticationService) {

  }

  ngOnInit(): void {


    /*  this.currentUserSubs = this.authService.currentUser.subscribe(item => {
       // console.log(item);
       this.currentUser = item;
       this.isUserLoggedIn = item;
     }); */
  }
  ngOnDestroy() {

  }

}
