import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  items: any;
  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'B:Consolidated Billing',
        icon: 'pi pi-pw pi-briefcase',
        items: [{
          label: 'BE:Debtors',
          icon: 'pi pi-pw pi-plus',
          items: [

            {
              icon: 'pi-plus',
              label: 'BE200:Debtor Accounts',

              items: [{
                icon: 'pi-angle-left',
                label: 'BE205:Debtor Account Maintenance',
                routerLink: ['/debtor/account-maintenance']
              }]
            },
          ]
        },
        {
          label: 'BF:Stands',
          icon: 'pi pi-pw pi-plus',
          items: [{
            icon: 'pi pi-pw pi-plus',
            label: 'BF100:Stands',

            items: [{
              icon: 'pi-angle-left',
              label: 'BF110-Unit Maintenance',
              routerLink: ['/stands/unit-maintenance']
            }]
          },
          {
            icon: 'pi pi-pw pi-plus',
            label: 'BF300: 2004 Rates Valuations',
            items: [{
              icon: 'pi pi-pw pi-plus',
              label: 'BF310:Interim Valuations',
              items: [{
                icon: 'pi-angle-left',
                label: 'BF311:Capture Proportional Valuations',
                routerLink: ['/stands/interim-proportional-valuation']
              },
              {
                icon: 'pi-angle-left',
                label: 'BF313:Authorise Proportional Valuations',
                routerLink: ['/stands/authorise-proportional-valuation']
              }]
            }]
          }
          ]
        },
        {
          label: 'BG:Meters',
          icon: 'pi pi-pw pi-plus',
          items: [

            {
              icon: 'pi-plus',
              label: 'BG200:Meter Readings',

              items: [{
                icon: 'pi-angle-left',
                label: 'BG205:Capture Meter Readings per Debtor',
                routerLink: ['/meters/capture-meter-reading']
              }]
            },
          ]
        },
        {
          label: 'BH:Services',
          icon: 'pi pi-pw pi-plus',
          items: [{
            icon: 'pi pi-pw pi-plus',
            label: 'BH100:Basic Services',

            items: [{
              icon: 'pi-angle-left',
              label: 'BH105:Basic Service Maintenance (BE,BW,BR,BS,BG)',
              routerLink: ['/bh-services/basic-services']
            }]
          },
          ]
        },
        {
          label: 'BJ:Journals',
          icon: 'pi pi-pw pi-plus',
          items: [{
            icon: 'pi pi-pw pi-plus',
            label: 'BJ085:Text-File Batch Journal Processing',

            items: [{
              icon: 'pi-angle-left',
              label: 'BJ086:Upload Bulk Journal From Textfile (Debits)',
              routerLink: ['/journals/upload-bulk-journal-debits']
            }]
          },
          ]
        },
        {
          label: 'BP:Reports',
          icon: 'pi pi-pw pi-plus',
          items: [{
            icon: 'pi pi-pw pi-plus',
            label: 'BP420:Meter Readings',

            items: [{
              icon: 'pi-angle-left',
              label: 'BP426:Meter Reading History (Active Meters)',
              routerLink: ['/meters/meter-reading-history']
            }]
          },
          ]
        },
        ]
      },
      {
        label: 'D:BCX Admin',
        icon: 'pi pi-pw pi-briefcase',
        items: [

          { label: 'DA:Access Control Admin' },
          { label: 'DG:GL Admin' }
        ]
      },
      {
        label: 'G:General Ledger',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          {
            icon: 'pi-plus',
            label: 'GC:Functional Codes',
            items: [
              {
                icon: 'pi-angle-left',
                label: 'GC010:Available Systems',
                routerLink: ['/general-ledger/gc010']
              },
              {
                icon: 'pi-angle-left',
                label: 'GC020:Journal Categories',
                routerLink: ['/general-ledger/gc020']
              },
              {
                icon: 'pi-angle-left',
                label: 'GC030:Expense Categories',
                routerLink: ['/general-ledger/gc030']
              }
            ]
          },
          { label: 'GD:Financial Period Control' },
          {
            icon: 'pi-plus',
            label: 'GF:Chart of Accounts Entity',
            items: [
              {
                icon: 'pi-angle-left',
                label: 'GF010:Account Types',
                routerLink: ['/general-ledger/gf010']
              },
              {
                icon: 'pi-angle-left',
                label: 'GF020:Sub Account Types',
                routerLink: ['/general-ledger/gf020']
              }
            ]
          },
          {
            icon: 'pi-plus',
            label: 'GG:Budgeting',
            items: [
              {
                icon: 'pi-angle-left',
                label: 'GG040:Budget Distribution Methods',
                routerLink: ['/general-ledger/gg040']
              },
              {
                icon: 'pi-angle-left',
                label: 'GG045:Budget Revision Types',
                routerLink: ['/general-ledger/gg045']
              }
            ]
          },
          {
            icon: 'pi-plus',
            label: 'GY:Installation Codes',
            items: [
              {
                icon: 'pi-angle-left',
                label: 'GY020-Coding Structure Words',
                routerLink: ['/general-ledger/gy020']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY060:Delimiter Charactern',
                routerLink: ['/general-ledger/gy060']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY070:Timesheet Types',
                routerLink: ['/general-ledger/gy070']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY110:Customer Code',
                routerLink: ['/general-ledger/gy110']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY140:Account Breakdown Definitions',
                routerLink: ['/general-ledger/gy140']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY150:Account Breakdown in Use by Client',
                routerLink: ['/general-ledger/gy150']
              },
              {
                icon: 'pi-angle-left',
                label: 'GY170:Generation of Fixed Codes for Implementation',
                routerLink: ['/general-ledger/gy170']
              },
            ]
          }
        ]
      },
      {
        label: 'H:Consolidated Stock Control',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          {
            label: 'HC:Codes',
          },
          {
            label: 'HC:Tenders',
          }
        ]
      },
      {
        label: 'I:Bank Control',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'IC:Codes' },
          { label: 'IF:Payment Control' }
        ]
      },
      {
        label: 'J:Job Costing',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'JC:Codes' },
          { label: 'JJ:Jobs' }
        ]
      },
      {
        label: 'K:Fleet Management',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'KC:Codes' },
          { label: 'KD:Administration' }
        ]
      },
      {
        label: 'M:Assets Register',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'MC:Codes' },
          { label: 'MD:Administration' }
        ]
      },
      {
        label: 'N:Treasury',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'NA:Investment Register' },
          { label: 'NB:External Loans Register' }
        ]
      },
      {
        label: 'Q:Captial Financing and Asset Management',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'RC:Codes' },
          { label: 'RD:Administration' }
        ]
      },
      {
        label: 'T:Internal Loan Register',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'TC:Codes' },
          { label: 'TD:Administration' }
        ]
      },
      {
        label: 'Z:Dynamenu',
        icon: 'pi pi-pw pi-briefcase',
        items: [
          { label: 'Za:Acess Control' },
          { label: 'ZI:Environment Defination' }
        ]
      },
    ];

  }

}
