import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  display = false;
  now: number;
  CurrentDate = new Date();
  currentUser: any;
  publicSites: any[];
  selectedPublicSite = '';
  appSites: any[];
  selectedAppSite = '';
  items: any[] = [];
  currentUserSubs: any;
  searchText = '';
  constructor(
    public authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private router: Router) {
    // setInterval(() => {
    //   this.now = Date.now();
    // }, 1);
  }

  ngOnInit(): void {
    // this.appSites = [{ label: ' SOLAR EMM Edenvale', value: 'solaremm' }]
    // this.loginCompleted({});
    this.currentUserSubs = this.authService.currentUser.subscribe(item => {
      // console.log(item);
      this.currentUser = item;
    });
  }

  // signInClick() {
  //   // this.display = true;
  // }

  signOutClick() {
    this.confirmationService.confirm({
      message: 'Logoff from Portal?',
      header: 'Logoff',
      accept: () => {
        this.selectedAppSite = '';
        this.items = [];
        // Actual logic to perform a confirmation
        this.authService.logout();
        // this.signInClick();
        this.currentUser = null;
        this.router.navigate(['/login']);
      },
      reject: () => {

      }
    });
  }

  loginCompleted(event) {
    this.currentUser = this.authService.currentUserValue;
    // this.display = !this.currentUser;
    this.router.navigate(['/']);
  }

  handleClose(event) {
    delete this.currentUser.selectedApp;
    this.authService.updateUser(this.currentUser);
    this.selectedAppSite = '';
    this.items = [];
  }

  onAppChange(event) {
    this.currentUser = this.authService.currentUserValue;
    this.currentUser.selectedApp = this.selectedAppSite;
    this.authService.updateUser(this.currentUser);
    const selectedAppRow = this.appSites.filter(a => a.value === this.selectedAppSite);
    this.items = [{ header: selectedAppRow[0].label }];
  }

  search(value) {
    if (value.toLowerCase() === 'bg205') {
      this.router.navigate(['/meters/capture-meter-reading']);
    } else if (value.toLowerCase() === 'bp426') {
      this.router.navigate(['/meters/meter-reading-history']);
    } else  if (value.toLowerCase() === 'be205') {
      this.router.navigate(['/debtor/account-maintenance']);
    } else if (value.toLowerCase() === 'bf110') {
      this.router.navigate(['/stands/unit-maintenance']);
    } else  if (value.toLowerCase() === 'bf311') {
      this.router.navigate(['/stands/interim-proportional-valuation']);
    } else if (value.toLowerCase() === 'bf313') {
      this.router.navigate(['/stands/authorise-proportional-valuation']);
    } else  if (value.toLowerCase() === 'bh105') {
      this.router.navigate(['/bh-services/basic-services']);
    } else if (value.toLowerCase() === 'bj086') {
      this.router.navigate(['/journals/upload-bulk-journal-debits']);
    } else if (value.toLowerCase() === 'bp426') {
      this.router.navigate(['/meters/meter-reading-history']);
    } else  {
      this.display = true;
    }
    this.searchText = '';

  }
}
