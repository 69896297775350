import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() loginCompleted: EventEmitter<any> = new EventEmitter();
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  invalidCred = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.invalidCred = false;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    // console.log(this.loginForm.controls.password.value);
    if (this.loginForm.controls.username.value.toLowerCase() !== 'admin' ||
      this.loginForm.controls.password.value.toLowerCase() !== 'password') {
      this.invalidCred = true;
      return;
    }
    this.loading = true;
    setTimeout(async () => {
      const isLoggedIn = await this.authenticationService.login(this.loginForm.controls.username.value,
        this.loginForm.controls.password.value);
      this.loading = false;
      this.loginForm.reset();
      this.submitted = false;
      this.router.navigate(['/']);
      // if (isLoggedIn) { this.router.navigate(['/']); } else { this.invalidCred = true; }

      /*  this.loginForm.controls.username.setValue('');
       this.loginForm.controls.username.markAsUntouched();
       this.loginForm.setErrors(null); */
      // this.loginCompleted.emit(true);
    }, 2000);
  }

  onClear() {
    this.submitted = false;
    if (this.loginForm) {
      this.loginForm.reset();
    }
  }
  ngOnDestroy() {

  }
}
