import { Component, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'SolarModernisation';
  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    this.authService.logout();
  }
  constructor(public authService: AuthenticationService) {

  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.authService.reset();
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    this.authService.reset();
  }
  @HostListener('document:keypress', ['$event'])
  keyPress(e) {
    this.authService.reset();
  }
  ngOnDestroy() {
    // this.authService.logout();
  }
}
