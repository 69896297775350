<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<!-- Toolbar -->
<app-header *ngIf="authService.userLoggedin"></app-header>
<div class="body-content">
  <div class="row">
    <div class="col-3" *ngIf="authService.userLoggedin">
      <app-side-menu></app-side-menu>
    </div>
    <div class="col-9">
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="authService.userLoggedin"></app-footer>
    </div>
  </div>
</div>